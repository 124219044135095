import * as React from "react";
import styled from "styled-components";

const StyledFooterLinks = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 1.25rem;
    @media screen and (min-width: 600px) {
    }
    @media screen and (min-width: 1020px) {
        grid-template-columns: repeat(8, auto);
    }
`;

const StyledFooter = styled.footer`
    padding-top: 4rem;
    display: grid;
    row-gap: 1rem;
    @media screen and (min-width: 600px) {
    }
    @media screen and (min-width: 1020px) {
        padding-top: 6rem;
        justify-content: space-between;
        display: flex;
    }
`;

const Footer: React.FunctionComponent = () => (
    <StyledFooter>
        <nav>
            <StyledFooterLinks>
                <li>
                    <a
                        className="link"
                        href="https://www.figma.com/@santiagomoreno"
                        target="_blank"
                    >
                        Figma
                    </a>
                </li>
                <li>
                    <a
                        className="link"
                        href="https://dribbble.com/santiagomoreno/"
                        target="_blank"
                    >
                        Dribbble
                    </a>
                </li>
                <li>
                    <a
                        className="link"
                        href="https://www.linkedin.com/in/sntiagomoreno/"
                        target="_blank"
                    >
                        Linkedin
                    </a>
                </li>
                <li>
                    <a
                        className="link"
                        href="https://github.com/sntiagomoreno"
                        target="_blank"
                    >
                        GitHub
                    </a>
                </li>
                <li>
                    <a
                        className="link"
                        href="https://twitter.com/sntiagomoreno"
                        target="_blank"
                    >
                        Twitter
                    </a>
                </li>
                <li>
                    <a
                        className="link"
                        href="https://instagram.com/santiagomoreno.co"
                        target="_blank"
                    >
                        Instagram
                    </a>
                </li>
                <li>
                    <a
                        className="link"
                        href="https://read.cv/santiagomoreno"
                        target="_blank"
                    >
                        Résumé
                    </a>
                </li>
            </StyledFooterLinks>
        </nav>
        <span>
            © {new Date().getFullYear()}
            {` `}
            Santigo Moreno
        </span>
    </StyledFooter>
);

export default Footer;
