import { Link } from "gatsby";
import * as React from "react";
import Text from "../Typography/Text";
import styled from "styled-components";

export const StyledMarquee = styled.div`
    overflow: hidden;
`;

type PluginItemProps = {
    name: string;
    description: string;
    url: string;
    img?: string;
};

type WorkItemProps = {
    name: string;
    description: string;
    path: string;
};

type OtherItemProps = {
    name: string;
    description: string;
    nda?: boolean;
    cta?: {
        text: string;
        url: string;
    };
};

type ItemProps = {
    Plugin: React.FunctionComponent<PluginItemProps>;
    Work: React.FunctionComponent<WorkItemProps>;
    Other: React.FunctionComponent<OtherItemProps>;
};

const Item: ItemProps = {
    Plugin: ({ name, description, url, img }) => {
        return (
            <li>
                <a
                    href={url}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    style={{ display: "block" }}
                    className="link"
                >
                    <div
                    // style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <span>
                            <strong>{name}</strong> {description}
                        </span>
                    </div>
                </a>
            </li>
        );
    },
    Work: ({ name, description, path }) => {
        return (
            <li>
                <Link to={path} style={{ display: "grid", rowGap: "0.5rem" }}>
                    <StyledMarquee data-text={name}>
                        <Text.Large interactive>{name}</Text.Large>
                    </StyledMarquee>
                    <Text.Small> {description} </Text.Small>
                </Link>
            </li>
        );
    },
    Other: ({ name, description, cta, nda }) => {
        return (
            <li>
                <h4>{name}</h4>
                <p>{description}</p>
                {cta ? (
                    <a
                        href={cta.url}
                        target="_blank"
                        referrerPolicy="no-referrer"
                        className="link"
                    >
                        {cta.text} ↗
                    </a>
                ) : (
                    <span style={{ opacity: 0.5 }}>
                        <em>{nda ? `Under NDA` : `Reach out to learn more`}</em>
                    </span>
                )}
            </li>
        );
    },
};

export default Item;
