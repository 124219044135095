import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";

const StyledBodyText = styled.span`
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    &.interactive {
        position: relative;
        transition: transform 150ms ease-in-out;
        &::before {
            content: attr(data-text);
            top: 100%;
            left: 0;
            position: absolute;
            display: block;
        }
    }
`;

const StyledSmallText = styled.span`
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    &.interactive {
        position: relative;
        transition: transform 150ms ease-in-out;
        &::before {
            content: attr(data-text);
            top: 100%;
            left: 0;
            position: absolute;
            display: block;
        }
    }
    @media screen and (min-width: 600px) {
        font-size: 1.125rem;
    }
    @media screen and (min-width: 1024px) {
        font-size: 1.25rem;
    }
`;

const StyledMediumText = styled.span`
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    &.interactive {
        position: relative;
        transition: transform 150ms ease-in-out;
        &::before {
            content: attr(data-text);
            top: 100%;
            left: 0;
            position: absolute;
            display: block;
        }
    }
    @media screen and (min-width: 600px) {
        font-size: 1.5rem;
    }
    @media screen and (min-width: 1024px) {
        font-size: 2rem;
    }
`;

const StyledLargeText = styled.span`
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 1.25;
    letter-spacing: -0.05em;
    display: block;
    &.interactive {
        position: relative;
        transition: transform 150ms ease-in-out;
        &::before {
            content: attr(data-text);
            top: 100%;
            left: 0;
            position: absolute;
            display: block;
        }
    }
    @media screen and (min-width: 600px) {
        font-size: 3rem;
    }
    @media screen and (min-width: 1024px) {
        font-size: 4rem;
    }
`;

type DataProps = {
    interactive?: boolean;
};

type TextProps = {
    Large: React.FunctionComponent<DataProps>;
    Medium: React.FunctionComponent<DataProps>;
    Small: React.FunctionComponent<DataProps>;
    Body: React.FunctionComponent<DataProps>;
};

const Text: TextProps = {
    Large: ({ children, interactive }) => (
        <StyledLargeText
            data-text={children}
            className={interactive ? `interactive` : ""}
        >
            {children}
        </StyledLargeText>
    ),
    Medium: ({ children, interactive }) => (
        <StyledMediumText
            data-text={children}
            className={interactive ? `interactive` : ""}
        >
            {children}
        </StyledMediumText>
    ),
    Small: ({ children, interactive }) => (
        <StyledSmallText
            data-text={children}
            className={interactive ? `interactive` : ""}
        >
            {children}
        </StyledSmallText>
    ),
    Body: ({ children, interactive }) => (
        <StyledBodyText
            data-text={children}
            className={interactive ? `interactive` : ""}
        >
            {children}
        </StyledBodyText>
    ),
};

export default Text;
