/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "../Header/Header";
import "../layout.css";
import { SiteTitleQueryQuery } from "../../../graphql-types";
import Footer from "../Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

const Layout = ({ children }) => {
    const data: SiteTitleQueryQuery = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <ParallaxProvider>
            <>
                <Header siteTitle={data.site.siteMetadata.title} />
                <div
                    style={{
                        margin: `0 auto`,
                        maxWidth: 1200,
                        padding: `0 1.0875rem 1.45rem`,
                    }}
                >
                    <main>{children}</main>
                    <Footer />
                </div>
            </>
        </ParallaxProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
