import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";
// @ts-ignore
import wip from "../../images/wip.png";
import Text from "../Typography/Text";
import { StyledMarquee } from "../Item/Item";

const StyledLogo = styled.span`
    margin: 0;
    font-weight: 700;
    font-size: 1.125rem;
    letter-spacing: -0.025em;
    position: relative;
    display: flex;
    align-items: center;
    @media screen and (min-width: 600px) {
        font-size: 1.5rem;
    }
    @media screen and (min-width: 1024px) {
    }
`;

const StyledNav = styled.nav`
    grid-column: 9 / span 4;
    ul {
        margin: 0;
        display: grid;
        column-gap: 1.25rem;
        justify-content: end;
        /* grid-template-columns: repeat(2, auto); */
        li {
            margin: 0;
        }
    }
`;

const StyledHeader = styled.header`
    padding: 1rem 1rem 4rem;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;

    align-items: end;
    @media screen and (min-width: 600px) {
    }
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(12, 1fr);
        padding: 2rem 1rem 4rem;
    }
`;

export type HeaderProps = {
    siteTitle: string;
};

const Header: React.FunctionComponent<HeaderProps> = ({ siteTitle }) => (
    <StyledHeader>
        <StyledLogo>
            <Link to="/">santiagomoreno </Link>
            <img
                src={wip}
                alt="Work in Progress"
                style={{ margin: "0 0.25rem", width: "1.5em" }}
            />
        </StyledLogo>
        <StyledNav>
            <ul>
                <li>
                    <Link to="/about" activeClassName="active-page">
                        <StyledMarquee>
                            <Text.Body interactive>about me</Text.Body>
                        </StyledMarquee>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/journey">Journey</Link>
                </li> */}
            </ul>
        </StyledNav>
    </StyledHeader>
);
export default Header;
